<template>
  <div style="min-height: 500px">
    <!--Filters-->
    <b-card no-body>
      <div  class="px-1 d-flex justify-content-end">
        <b-link style="color: inherit" @click="refresh()">
          <feather-icon icon="RefreshCcwIcon" size="16"/>
        </b-link>
      </div>
      <div class="px-1">
        <b-row>
          <b-col>
            <b-form-group :label="msg('Building')" label-for="realEstate-branch">
              <building-select-box multiple v-model="filter.buildingIds" @input="refresh"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="msg('Real estate')" label-for="realEstate-branch">
              <real-estate-picker multiple v-model="filter.realEstatesIds" @input="refresh"/>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!--Tasks-->
    <b-row  align-v="stretch">
      <!--Building Task-->
      <b-col
          lg="3"
          md="4"
          cols="12"
          class="pb-1"
          v-for="b in groupedByBuilding"
          v-bind:key="b.id"
          align-self="stretch"
      >        <b-card no-body  style="overflow: hidden; height: 100%" >
          <div class="card-header"  style="overflow: hidden">
            <p class="card-title m-0"> <i class="fa-solid" v-bind:class="{'fa-building':b.type==='building', 'fa-house':b.type==='realEstate' }"></i>
              {{ b.address }}
            </p>
          </div>
          <!--Task list-->
          <div>
            <b-list-group  style="border: 0; border-radius: 0">
              <!--Task-->
              <b-list-group-item v-bind:variant="task.variant" v-for="task in b.tasks" v-bind:key="task.id" class="flex-column align-items-start customView"
                                 @click.stop="openModal(task.id)">

                <div class="d-flex w-100 justify-content-between ">
                  <h5 class="mb-1 text-capitalize">{{$t('apt')}}: {{ task.realEstate.apt }}</h5>
                  <small v-if="task.hasReservation">{{task.requestedCleanTimeEnd | momentTimeFromNow()}}</small>
                </div>

                <p class="mb-1">
                  <b>{{msg("Reservation")}}:</b>
                  <a :href="`/real_estate/contracts/rent/${task.contractId}`" target="_blank">
                    {{task.clientName}} {{msg("until")}} {{task.requestedCleanTime | moment('DD/MM/YY HH:mm', true)}}
                  </a>
                </p>
                <p class="mb-1" v-if="task.hasReservation">
                  <b>{{msg("Next Reservation")}}:</b>
                  <a :href="`/real_estate/contracts/rent/${task.nextReservationId}`" target="_blank">
                    {{task.nextReservationName}} {{msg("at")}} {{task.requestedCleanTimeEnd | moment('DD/MM/YY HH:mm', true)}}
                  </a>
                </p>
                <div class="d-flex align-items-center justify-content-between">
                  <!-- Button on the Left -->
                  <button
                      class="btn btn-outline-secondary btn-sm"
                      @click.stop="openTaskPage(task.id)"
                  >
                    <i class="fa-solid fa-file-export"></i>
                  </button>

                  <!-- Badge on the Right -->
                  <b-badge :variant="task.statusDetails.variant">
                    {{ task.statusDetails.label }}
                  </b-badge>
                </div>

                <!-- Notes Section -->
                <div>
                  <small>
                    <b v-if="task.notes != null && task.notes !== ''">{{ msg("Note") }}:</b>
                    {{ task.notes }}
                  </small>
                </div>

              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card>
        <div class="clearfix"></div>

      </b-col>
    </b-row>
    <cleaning-edit-modal ref="cleaningModal" @updated="refresh"/>
  </div>
</template>
<script>

import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import {mapActions} from "vuex";
import cleaningEditModal from "@/components/modals/cleaning/cleaningEditModal.vue";
import buildingSelectBox from "@/components/components/picker/buildingSelectBox.vue";

const getCleaningFilter = ()=>({
  realEstatesIds: [],
  buildingIds: []
});
export default {
  name: "CleaningTaskView",
  components: {RealEstatePicker,buildingSelectBox,cleaningEditModal},
  data: () => ({
    filter: getCleaningFilter(),
    tasks: [],
    realEstatesMap: {}
  }),
  created() {
    this.refresh()
    this.$root.$on('crm::branch-updated', this.refresh);
  },
  destroyed() {
    this.$root.$off('crm::branch-updated', this.refresh);
  },
  computed:{
    statusEnum() {
      return this.$t('cleaningStatusEnum');
    },
    groupedByBuilding() {
      if(this.tasks == null) return {};
      let result = {};
      this.tasks.forEach(task=>{
        let realEstate = this.realEstatesMap[task.realEstateId];
        if(realEstate == null) {
          console.warn("Real estate not found", task.realEstateId, this.realEstatesMap)
          return
        }
        let building = task.buildingId || task.realEstateId;
        if(result[building] == null) {
          result[building] = {
            id: building,
            type: task.buildingId == null ? 'realEstate' : 'building',
            address: task.buildingId == null ? realEstate.fullAddress : realEstate.locationAddress,
            tasks: []
          }
        }
        let variant = null;
        if(task.isCleaningRequired && task.isCloseToReservation) {
          variant = 'warning';
        }else if(task.isCleaningLate) {
          variant = 'secondary';
        } else if(task.hasStated) {
          variant = 'info';
        }
        result[building].tasks.push({...task,realEstate, variant, statusDetails: this.statusEnum[task.status]});
      });
      return Object.values(result).sort((a,b)=>b.tasks.length - a.tasks.length);
    }
  },
  methods: {
    ...mapActions('cleaning', ['fetchCleaningTasks']),
    refresh() {
      let $this = this;
      this.fetchCleaningTasks(this.filter).then(data=>{
        console.log("refresh tasks", data);
        this.tasks = $this.checkTasks(data.CleaningTasks);
        this.realEstatesMap = (data.RealEstates || []).toObject('id');
      }).catch(()=>{
        $this.$toast.error($this.msg('Error refreshing data'))
      })
    },
    openTaskPage(eventId) {
      window.open(`/cleaning/edit/${eventId}`, '_blank');
    },
    checkTasks(tasks) {
      let $this = this;
      let now = this.$moment();
      return tasks.map(task => {
        task.hasStated = task.status !== "NEED_CLEANING" && task.status !== "CLEANED" && task.status !== "CANCELLED" && task.status !== "OVERDUE";
        task.hasReservation = task.nextReservationId != null;
        if(task.hasReservation){
          task.isCloseToReservation = $this.$moment(task.requestedCleanTimeEnd).isBefore(now.add(3, 'hour'));
          task.isTimeEnded = $this.$moment(task.requestedCleanTimeEnd).isBefore(now);
          task.isCleaningLate = !task.hasStated && task.isTimeEnded && !task.cleaned;
        }
        task.isCleaningRequired = !task.isTimeEnded && $this.$moment(task.requestedCleanTime).isBefore(now);
        return task;
      })
    },
    resetFilters() {
      this.filter = getCleaningFilter();
    },
    openModal(taskId) {
      // let task = this.tasks.find(t=>t.id === taskId);
      // this.$refs.cleaningModal.show(task, this.realEstatesMap[task.realEstateId]);
       this.$refs.cleaningModal.showById(taskId);
    }
  }
}
</script>


<style scoped>
.dark-layout .customView {
  background: rgba(30, 31, 34, 0) !important;
  border-bottom: dashed 1px #fff;
}
</style>
